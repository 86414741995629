import { TYPESENSE_DOCUMENT_HIT, TYPESENSE_RESPONSE_DTO } from '../utils/constants'
import { css, cssBatch, find } from '../utils/dom'
import showToastify from '../utils/toastify'
import axios from 'axios'

function generateTextFragmentLink(baseUrl, selectedText) {
	if (!baseUrl || !selectedText) {
		throw new Error('Base URL and selected text are required')
	}

	// Encode the selected text to ensure it works in URLs
	const encodedText = encodeURIComponent(selectedText)

	// Construct the URL with the text fragment directive
	const fragmentLink = `${baseUrl}#:~:text=${encodedText}`

	return fragmentLink
}

const origin =
	import.meta.env === 'production' ? window.location.origin : 'https://instituto-djanira.dokku-sites.novadata.com.br'

export default function () {
	const url = `${origin}/typesense_search/`
	const urlArtistaHighlight = (text) => generateTextFragmentLink(`${origin}/a_artista/`, text)
	const urlNoticias = (slug) => `${origin}/novidades/${slug}`

	const $ = {
		input: find('#search-input'),
		closeModal: find('#search-close'),
		openModal: find('#search-open'),
		searchSectionActive: find('#search-active'),
		searchSectionInactive: find('#search-inactive'),
		empty: find('#search-empty'),
		biografia: {
			section: find('#search-biografia'),
			hits: find('#search-biografia .Hits'),
			qnt: find('#search-biografia .qnt'),
		},
		cronologia: {
			section: find('#search-cronologia'),
			hits: find('#search-cronologia .Hits'),
			qnt: find('#search-cronologia .qnt'),
		},
		noticias: {
			section: find('#search-noticias'),
			hits: find('#search-noticias .Hits'),
			qnt: find('#search-noticias .qnt'),
		},
	}

	const classHitArtista = 'text-neutral-70 text-ellipsis text-[1.5rem] py-4 border-b border-b-neutral-10'

	const hitTemplateArtista = (hit) =>
		hit.highlight.contents
			?.map(
				({ snippet, matched_tokens }) =>
					`<a class='${classHitArtista}' href='${urlArtistaHighlight(
						snippet.replaceAll('<mark>', '').replaceAll('</mark>', '')
					)}'> ${snippet}</a>`
			)
			.join()

	const hitTemplateCronologia = (hit) =>{
		const snippet = hit.highlight?.custom_descricao?.snippet
		if (!snippet) return ""
		return `<a class='${classHitArtista}' href='${urlArtistaHighlight(
						snippet.replaceAll('<mark>', '').replaceAll('</mark>', '')
					)}'> ${snippet}</a>`
	}

	/** @param {TYPESENSE_DOCUMENT_HIT} hit */
	const hitTemplateNoticias = ({ document, highlight }) => `
	<a href="${urlNoticias(document.slug)}" class="group flex h-auto w-1/3 max-w-[566px] flex-col md:w-full">
		<div class="relative mb-24 h-[244px] w-full overflow-hidden">
			<img  src="${document.thumbnail}" alt="${
		document.titulo
	}" class="absolute inset-0 m-auto size-full object-cover transition-transform hover:scale-105" />
			</div>
			<span class="text-[16px] text-[#4E4A8D]" >${document.categoria}</span>
			<p class="!font- mt-8 !font-bold text-[#4E4A8D] text-headline-20 group-hover:text-primary-02">${
				highlight.titulo?.snippet
			}</p>
	</a>
	`

	function setQnts({ biografia, cronologia, novidades }) {
		$.biografia.qnt.innerText = biografia.found
		$.cronologia.qnt.innerText = cronologia.found
		$.noticias.qnt.innerText = novidades.found
	}

	/** @param {TYPESENSE_RESPONSE_DTO} data  */
	function setHits(data) {
		const { biografia, cronologia, novidades } = data
		$.biografia.hits.innerHTML = biografia.hits?.map(hitTemplateArtista).join() || ''
		$.cronologia.hits.innerHTML = cronologia.hits?.map(hitTemplateCronologia).join() || ''
		$.noticias.hits.innerHTML = novidades.hits?.map(hitTemplateNoticias).join() || ''
	}

	function showInativeSearchScreen() {
		css($.searchSectionInactive, { display: 'flex' })
		css($.searchSectionActive, { display: 'none' })
		css($.empty, { display: 'none' })
	}

	function hideShowSections({ biografia, cronologia, novidades }, value) {
		const noHaveResults = [biografia.found, cronologia.found, novidades.found].every((v) => !!!v)

		biografia.found ? css($.biografia.section, { display: 'flex' }) : css($.biografia.section, { display: 'none' })
		cronologia.found ? css($.cronologia.section, { display: 'flex' }) : css($.cronologia.section, { display: 'none' })
		novidades.found ? css($.noticias.section, { display: 'flex' }) : css($.noticias.section, { display: 'none' })

		if (!value) {
			css($.searchSectionInactive, { display: 'flex' })
			css($.searchSectionActive, { display: 'none' })
		} else {
			css($.searchSectionInactive, { display: 'none' })
			css($.searchSectionActive, { display: 'flex' })
		}

		if (noHaveResults && value) {
			css($.empty, { display: 'flex' })
			css($.searchSectionInactive, { display: 'none' })
			css($.searchSectionActive, { display: 'none' })
		} else {
			css($.empty, { display: 'none' })
		}
	}

	/** @param {TYPESENSE_RESPONSE_DTO} data  */
	function generateHTML(data, value) {
		setQnts(data)
		setHits(data)
		hideShowSections(data, value)
	}

	async function search(e) {
		const { value } = e.target

		try {
			const req = await axios.get(`${url}?q=${value}`)
			/** @type {TYPESENSE_RESPONSE_DTO}  */
			const data = req.data
			if (!data) return
			generateHTML(data, value)

			console.log(data)
		} catch (error) {
			console.log(error)
			showToastify('Ocorreu um erro ao buscar')
		}
	}

	function init() {
		$.input.addEventListener(
			'keydown', //
			GLOBAL.debounce(search, 400)
		)
		$.closeModal.addEventListener('click', () => {
			$.input.value = ''
			showInativeSearchScreen()
		})
		$.openModal.addEventListener('click', () => {
			showInativeSearchScreen()
		})

		if (window.matchMedia('(max-width: 1180px)').matches) {
			find('.button-search-mobile')?.appendChild($.openModal)
		}
	}

	return {
		init,
	}
}
